import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

class CalendarDataService {
  getCalendar(params) {
    return axios.get(API_URL + '/calendar', { params: params });
  }

}

export default new CalendarDataService();

import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

class StreamInfoService {
  getStreamInfo() {
    return axios.get(API_URL + '/streamInfo', {});
  }

}

export default new StreamInfoService();

import { createStore } from 'vuex'

export default createStore({
  state: {
    streamInfo: {
      user_login: '',
      live: false,
      title: ''
    }
  },
  getters: {
    live: state => {
      return state.streamInfo.live
    },
    user_login: state => {
      return state.streamInfo.user_login
    },
    title: state => {
      return state.streamInfo.title
    }
  },
  mutations: {
    changeStreamInfo (state, payload) {
      state.streamInfo.live = (payload && payload.type && payload.type == 'live') ? true : false;
      state.streamInfo.user_login = (payload && payload.user_login) ? payload.user_login : '';
      state.streamInfo.title = (payload && payload.title) ? payload.title : '';
    }
  },
  actions: {
  },
  modules: {
  }
})

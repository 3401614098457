import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import StreamView from '../views/StreamView.vue'
import ProgrammationView from '../views/ProgrammationView.vue'

const routes = [
  {
    path: '/',
    redirect: { path: "/stream" }
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // },
  {
    path: '/stream',
    name: 'Stream',
    component: StreamView
  },
  {
    path: '/programmation',
    name: 'Programmation',
    component: ProgrammationView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = "Nydre TV - " + to.name;
  next();
});

export default router

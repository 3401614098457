<template>
    <div class="programmation">

        <div class="row d-flex justify-content-center m-2">
            <div class="date-changer-box d-flex justify-content-center align-items-center">
                <div class="date-btn" @click="this.getPrevWeek()">
                    &#8249;
                </div>
                <div class="selected-week m-2">
                    {{ getPrettyWeek() }}
                </div>
                <div class="date-btn" @click="this.getNextWeek()">
                    &#8250;
                </div>
            </div>
        </div>
        


        <div class="calendar-container">
                <div class="hours-legend">
                    <div class="day-title day-hour-title">
                        <img :src="nLogo" class="calendar-logo"/>
                    </div>
                    <div class="day-prog d-flex flex-column">
                        <div v-for="hour in this.hours" class="day-hour" :key="hour"
                        :style="{top: 'calc(calc(100% / ' + this.hoursSize + ') * ' + (this.getEventDateOffset(hour) - this.minHour) + ')',
                            height: 'calc(calc(100% / ' + this.hoursSize + ') * ' + (this.getEventDurationHeight({hours: 1})) + ')'}"
                        
                        >
                        <div class="day-hour-line">

                        </div>
                        <div class="day-hour-text">
                            {{  getPrettyHour(hour) }}
                        </div>
                            
                        </div>
                    </div>

                </div>
                <div class="calendar">
                    <div v-for="day in this.days" class="day-col" :key="day">
                        <div class="day-title">
                            {{ this.getPrettyDayOfMonth(day?.date) }}
                        </div>
                        <div v-if="!this.isLoading" class="day-prog">
                            <div v-for="event in day.events" class="day-event" :key="event"
                            :style="{top: 'calc(calc(100% / ' + this.hoursSize + ') * ' + (this.getEventDateOffset(event.start.date) - this.minHour) + ')',
                            height: 'calc(calc(100% / ' + this.hoursSize + ') * ' + (this.getEventDurationHeight(event.duration)) + ')'}"
                            
                             >
                             
                                <img class="event-bg-image"
                                :src="getCategoryImgUrl(event.category)" v-bind:alt="event.category"
                                />
                            
                                
                                <div class="event-hours">
                                    {{ this.getPrettyHour(event.start.date) }}-{{ this.getPrettyHour(event.end.date) }}
                                </div>
                                <div class="event-title">
                                    {{ event.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            <div v-if="this.isLoading" class="logo-loader">
                <img :src="nLogo" class="loader-logo"/>
            </div>
        </div>
        
    </div>
  </template>
  
  <script>
  import CalendarDataService from '@/services/CalendarDataService'
  import { fr } from "date-fns/locale"
  import { startOfWeek, endOfWeek, toDate, getHours, intervalToDuration, setHours, setMinutes, addDays, subDays, format, setDefaultOptions, getMinutes } from "date-fns";
  import nLogo from "@/assets/n-logo.png"

  // @ is an alias to /src
  
  export default {
    name: 'ProgrammationView',
    data() {
        return {
            days: [
                "Lundi",
                "Mardi",
                "Mercredi",
                "Jeudi",
                "Vendredi",
                "Samedi",
                "Dimanche"
            ],
            selectedDay: null,
            startOfWeek: null,
            endOfWeek: null,
            minHour: 9,
            maxHour: 22,
            hours: [],
            hoursSize: 13,
            nLogo: nLogo,
            isLoading: false
        }
    },
    mounted() {
        setDefaultOptions({ locale: fr })
        setDefaultOptions({ weekStartsOn: 1 })
        this.setSelectedDay(setMinutes(setHours(toDate(new Date()), 0), 0))
        this.getCalendar()
    },
    methods: {
        async getCalendar() {
            this.isLoading = true
            this.events = (await CalendarDataService.getCalendar({
                "startOfWeek": format(this.startOfWeek, 'yyyy-MM-dd'),
                "endOfWeek": format(this.endOfWeek, 'yyyy-MM-dd')
            })).data

            for (let i in this.events) {
                
                this.events[i].start.date = toDate(this.events[i].start.dateTime).toLocaleString("en-US", {timeZone: "Europe/Paris"});
                this.events[i].end.date = toDate(this.events[i].end.dateTime).toLocaleString("en-US", {timeZone: "Europe/Paris"});
                this.events[i].duration = intervalToDuration({
                    start: this.events[i].start.date,
                    end: this.events[i].end.date
                })
                let day = setMinutes(setHours(this.events[i].start.date, 0), 0)
                

                const foundDay = this.days.find(e => e.date.getTime() == day.getTime())
                if (foundDay) foundDay.events.push(this.events[i])
                // else this.days.push({date: day, events: [this.events[i]]})


                
                let beginHour = getHours(this.events[i].start.date)
                let endHour = beginHour + this.events[i].duration.hours + (((this.events[i].duration.minutes ? this.events[i].duration.minutes : 0) / 60) * 100)
                if (endHour > 24) endHour = 24
                if (endHour > this.maxHour) this.maxHour = endHour
                if (beginHour < this.minHour) this.minHour = beginHour
            }
            
            this.initHours()
            setTimeout(() => {
                this.isLoading = false
            }, 1000);

        },

        setSelectedDay(day) {
            this.selectedDay = toDate(day);
            this.minHour = 9
            this.maxHour = 22
            this.hoursSize = 13
            this.initHours()

            this.startOfWeek = startOfWeek(this.selectedDay)

            this.endOfWeek = endOfWeek(this.selectedDay)

            this.initDays()
        },

        initHours() {
            this.hours = []
            let hour = this.minHour
            while (hour < this.maxHour) {
                let dateHour = new Date(1970, 0, 0, hour, 0, 0)
                this.hours.push(dateHour)
                hour += 1
            }
            this.hoursSize = this.maxHour - this.minHour


        },

        initDays() {
            this.days = []
            let day = this.startOfWeek
            while (day.getTime() <= this.endOfWeek.getTime()) {
                this.days.push({date: day, events: []})
                day = addDays(day, 1)
            }
        },

        getPrevWeek() {
            this.setSelectedDay(subDays(this.selectedDay, 7))
            this.getCalendar()
        },

        getNextWeek() {
            this.setSelectedDay(addDays(this.selectedDay, 7))
            this.getCalendar()
        },

        getPrettyDayOfMonth(day) {
            if (day) {
                let dayStr = format(day, "eeee d")
                return dayStr.charAt(0).toUpperCase() + dayStr.slice(1);
            }
            return ""
        },

        getPrettyHour(datetime) {
            return format(datetime, 'HH:mm')
        },

        getPrettyWeek() {
            if (this.startOfWeek && this.endOfWeek) return format(this.startOfWeek, 'd MMM') + ' - ' + format(this.endOfWeek, 'd MMM')
            return ''
        },

        getEventDateOffset(datetime) {
            if (datetime) {
                let hour = getHours(datetime) + (getMinutes(datetime) / 60)
                if (hour == 0) hour += 24
                return hour
            }
            return 0
        },

        getEventDurationHeight(duration) {
            if (duration) {
                return (duration.hours ? duration.hours : 0) + (duration.minutes ? duration.minutes / 60 : 0)

            }
            return 1
        },

        getCategoryImgUrl(pic) {
            return '/assets/category-bg/' + pic + '.png'
        },

    }
  }
  </script>

<style>

.programmation {
    background-color: #121212;
    width: 100%;
}

.calendar-container {
    user-select: none;
    
    margin: auto;
    margin-top: 20px;

    display: flex;
    height: 80vh;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: stretch;
    display: flex;
    border-radius: 30px;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #605F5F;
    padding: 10px;
    position: relative;
    
}

.logo-loader {
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #12121275;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.loader-logo {
    width: 80px;
    height: 80px;
    animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



.hours-legend {
    height: 100%;
    min-width: 120px;
    width: 15%;
    /* background-color: #605F5F; */
    border-right: 1px solid #121212;
}

.calendar {
    display: flex;
    overflow-x: auto;
    height: 100%;
    width: 85%;
}

.day-col {
    width: calc(100% / 7);
    height: 100%;
    min-width: 150px;
    /* max-width: 180px; */
    background-color: transparent;
    border-right: 1px solid #121212;
}

.day-col:last-of-type {
    border-right: none;
}

.day-title {
    height: 100px;
    border-bottom: 1px solid #121212;
    /* color: white; */
    /* text-shadow: 0px 0px 24px #E0F9FF; */
    align-items:center;
    justify-content:center;
    display: flex;
    font-size: 24px;
    font-weight: bold;
}

.day-hour-title {
    border-bottom: none;
}

.day-prog {
    height: calc(100% - 100px);
    /* background-color: rgba(245, 245, 220, 0.498); */
    position: relative;
    /* color: white; */
}

.day-hour {
    position: absolute;
    
    width: 100%;
}

.day-hour-text {
    position: absolute;
    top: -10px;
    height: 20px;
    font-size: 14px;
    left: 0;
    width: 35%;
    text-align: center;
}

.day-hour-line {
    position: absolute;
    width: 65%;
    height: 100%;
    right: 0;
    top: 0;
    /* border-bottom: 1px solid white; */
    border-top: 1px solid #121212;
}

.day-event {
    position: absolute;
    width: 100%;
    overflow: hidden;
    /* border: 1px solid #121212; */
    border-radius: 10px;
}

.day-event:hover {
    border: 1px solid #E0FFEF;
}

.event-title {
    position: absolute;
    /* margin-bottom: 3px; */
    margin-left: auto;
    margin-right: auto;
    padding: 2px;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 16px;
    font-weight: bold;
    line-height: 18px;
    background-color:rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.5);
    
    /* border-radius: 5px; */
    /* width: fit-content; */
}

.event-hours {
    position: absolute;
    top: 0;
    left: 0;
    padding: 1px;
    font-size: 10px;
    background-color:rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.5);
}

.event-bg-image {
    width: 100%;
    resize: horizontal;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;

}

.day-event:hover .event-bg-image {
    filter: blur(5px) brightness(50%);
}

.day-event:hover .event-hours {
    right: 0;
    margin: auto;
    background-color: transparent;
    box-shadow: none;
    font-size: 14px;
}

.day-event:hover .event-title {

    top: 0;
    bottom: 0;
    height: fit-content;
    background-color: transparent;
    box-shadow: none;
    margin: auto;
    font-size: 18px;
}

.calendar-logo {
    width: 60px;
    height: 60px;
}

.date-btn {
    text-decoration: none;
    user-select: none;
    display: inline-block;
    padding: 8px 16px;
    background-color: #605F5F;
    color: #121212;
    border-radius: 50%;
    border: 1px solid transparent;
}

.date-btn:hover {
    background-color: #3e3e3e;
    color: #E0FFEF;
    cursor: pointer;
    border: 1px solid #E0FFEF;
}

</style>
<template>
  <!-- <img src="/assets/n-logo.png" class="logo"/> -->
  <nav class="navbar navbar-expand-lg main-navbar">
    
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link to="/stream" class="d-flex justify-content-center"><div>Stream</div> <div v-if="this.streamInfo?.type == 'live'" class="live-circle-wrapper"><div class="live-circle"></div></div></router-link>
          </li>
          <li class="nav-item">
            <router-link to="/programmation">Programmation</router-link>
          </li>
        </ul>
    </div>
  </nav>
  <div class="socials-box">
    <ul>
      <li><a href="https://www.twitch.tv/nydre_" target="_blank"><i><font-awesome-icon :icon="['fab', 'twitch']" /></i> Twitch</a></li>
      <li><a href="https://discord.gg/SUMGWccvgc" target="_blank"><i><font-awesome-icon :icon="['fab', 'discord']" /></i> Discord</a></li>
      <li><a href="https://twitter.com/nydre__" target="_blank"><i><font-awesome-icon :icon="['fab', 'x-twitter']" /></i> X</a></li>
      <li><a href="https://www.instagram.com/nydre__" target="_blank"><i><font-awesome-icon :icon="['fab', 'instagram']" /></i> Instagram</a></li>
    </ul>
  </div>
  <router-view/>
</template>

<script>
import StreamInfoService from '@/services/StreamInfoService';

export default {
    name: 'App',
    data() {
      return {
        streamInfo: null
      }
    },
    mounted() {
      this.getStreamInfo()

      setInterval(function () {
        this.getStreamInfo()
      }.bind(this), 30000); 

    },
    methods: {
      async getStreamInfo() {
        this.streamInfo = (await StreamInfoService.getStreamInfo()).data
        this.$store.commit("changeStreamInfo", this.streamInfo)
      }
    }
}
</script>

<style>

nav {
  z-index: 1;
  background-color: #E0FFEF;
}

#app {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #121212;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #E0F9FF;
  height: 100vh;
}

nav {
  padding: 30px;
  background-color: #3C3C3C;
  color: #E0FFEF;
}

.logo {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 10px;
  width: 50px;
  height: 50px;
}

.socials-box {
  display:none;

  position: fixed;
  top: 10px;
  right: 0;
  z-index: 2;
  
  /* width: 600px;
  height: 600px;
  position: absolute;
  top: 0;
  right: 0; */
  /* max-height: 63px;
  min-width: 100px;
  max-width: 200px;
  background-color: green;
  border-bottom-left-radius: 20px;
  border-left: 1px solid #E0FFEF;
  border-bottom: 1px solid #E0FFEF; */
}

.socials-box ul {
  padding: 0px;
  transform: translate(250px, 0);
}

.socials-box ul li {
  display: block;
  margin: 5px;
  background: #605F5F;
  width: 300px;
  text-align: left;
  padding: 10px;
  border-radius: 30px 0px 0px 30px;
  transition: all 1s;
}

.socials-box ul li:hover {
  transform: translate(-110px, 0);
  background: #D2D2D2;
}

.socials-box ul li:hover a {
  color: #121212;
}

.socials-box ul li a {
  color: #121212;
  text-decoration: none;
}

.socials-box ul li:hover i {
  color: #FCFCFCFC;
  background: #252525;
  transform: rotate(360deg);
  transition: all 1s;
}

.socials-box ul li i {
  margin-right: 10px;
  color: #000;
  background: #fff;
  padding: 10px;
  border-radius: 50%;
  display:inline-block;
  width: 40px;
  height: 40px;
  font-size: 20px;
  background: #FCFCFCFC;
  transform: rotate(0deg);
  line-height: 20px;
}

.svg-inline--fa {
    max-width: 20px;
    max-height: 20px;
    min-width: 20px;
    min-height: 20px;
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    max-width: 960px;
    mask-image: linear-gradient(90deg, #12121200 0%, #E0FFEF 25%, #E0FFEF 75%, #12121200 100%);
    margin: 0 auto 20px auto;
    padding: 60px 0;
  }
  .navbar-collapse {
    justify-content: center;
  }

  nav ul {
    text-align: center;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 25%, rgba(255, 255, 255, 0.2) 75%, rgba(255, 255, 255, 0) 100%);
    
    /* box-shadow: 0 0 25px rgba(0, 0, 0, 0.1), inset 0 0 1px rgba(255, 255, 255, 0.6); */
  }

  .navbar {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  nav ul li {
    display: inline-block;
  }

  nav ul li a {
    padding: 18px;
    text-transform:uppercase;
    color: #E0FFEF;
    font-size: 18px;
    text-decoration: none;
    display: block;
  }

  nav ul li a:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1), inset 0 0 1px rgba(255, 255, 255, 0.6);
    background: rgba(255, 255, 255, 0.1);
    color: #c6ffce;
  }

  .logo {
      display: block;
      
  }

  .socials-box {
    display: block;
  }
}


nav a {
  font-weight: bold;
  color: #E0FFEF;
}

nav a.router-link-exact-active {
  color: #81ff92 !important;
}

.navbar-toggler {
  margin-left: auto;
  margin-right: 10px;
}

.router-link-flex {
  display: flex;
}

.live-circle-wrapper {
  width: 40px;
  height: 27px;
  position: relative;
}

.live-circle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 20px;
  height: 20px;

  &:before {
    content: "";
    position: relative;
    display: block;
    width: 200%;
    height: 200%;
    box-sizing: border-box;
    margin-left: -50%;
    margin-top: -50%;
    border-radius: 45px;
    background-color: #75daad;
    animation: pulse 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #75daad;
    border-radius: 50px;
    animation: circle 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}

@keyframes circle {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}




</style>

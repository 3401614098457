<template>
    <div class="big-container d-flex justify-content-center">
      
      <div class="stream-container">
        <div v-if="!this.liveStatus" class="no-stream d-flex flex-column align-items-center justify-content-center">
        <div class="no-stream-title">
          Je ne suis pas en live actuellement :(
        </div>
        <button class="prog-button">
          <router-link to="/programmation"><span>Programmation</span></router-link>
        </button>
      </div>
        <div v-if="this.liveStatus == true" id="twitch-embed-live"></div>
        <div id="twitch-embed-chat">
          <iframe id="twitch-chat-embed"
                  src="https://www.twitch.tv/embed/nydre_/chat?parent=nydre.tv"
                  height="100%"
                  width="100%">
          </iframe>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import { loadScript } from "vue-plugin-load-script";
  
  export default {
    name: 'StreamView',
    data() {
      return {
        user_login: '',
        liveStatus: false,
        streamTitle: '',
        twitchLiveStream: null
      }
    },
    methods: {
      loadLivestream() {
        loadScript("https://embed.twitch.tv/embed/v1.js")
            .then(() => {
                this.twitchLiveStream = new window.Twitch.Embed("twitch-embed-live", {
                    width: "100%",
                    height: "100%",
                    channel: this.user_login != "" ? this.user_login : 'nydre_',
                    layout: "video"
                });
            })
            .catch(() => {
              // Failed to fetch script
            });
      }
    },
    mounted() {
      this.user_login = this.$store.getters.user_login
      this.liveStatus = this.$store.getters.live
      this.streamTitle = this.$store.getters.title
      this.unsubscribe = this.$store.subscribe((mutation, state) => {
        if (this.user_login != state.streamInfo.user_login) this.user_login = state.streamInfo.user_login
        
        if (this.streamTitle != state.streamInfo.title) this.streamTitle = state.streamInfo.title

        if (this.liveStatus != state.streamInfo.live) {
          this.liveStatus = state.streamInfo.live
          if (this.liveStatus == true) {
            this.loadLivestream()
          }
        }
      })
      if (this.liveStatus == true) {
        this.loadLivestream()
      }
    },
    unmounted() {
      if (this.unsubscribe) {
        this.unsubscribe()
      }
    }
  }
  </script>

<style>

.big-container {
  user-select: none;
    
    margin: auto;
    margin-top: 20px;

    display: flex;
    height: 80vh;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: stretch;
    display: flex;
    border-radius: 30px;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #605F5F;
    padding: 10px;
    position: relative;
}

.stream-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

#twitch-embed-live {
  width: 70%;
  height: 90%;

}
.no-stream {
  width: 70%;
  height: 90%;

}

#twitch-embed-chat {
  width: 25%;
  height: 90%;
}

/* prog-button */

.no-stream-title {
  margin-bottom: 20px;
}

button {
  margin: 20px;
  outline: none;
}
.prog-button {
  width: 130px;
  height: 40px;
  padding: 10px 25px;
  border: 2px solid #000;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
}

.prog-button a {
    color: #FCFCFCFC;
    text-decoration: none;
}

.prog-button {
   background: #000;
  color: #fff;
  line-height: 42px;
  padding: 0;
  border: none;
}
.prog-button span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.prog-button:before,
.prog-button:after {
  position: absolute;
  content: "";
  height: 0%;
  width: 2px;
  background: #000;
}
.prog-button:before {
  right: 0;
  top: 0;
  transition: all 500ms ease;
}
.prog-button:after {
  left: 0;
  bottom: 0;
  transition: all 500ms ease;
}
.prog-button:hover{
  color: #000;
  background: transparent;
}
.prog-button:hover:before {
  transition: all 500ms ease;
  height: 100%;
}
.prog-button:hover:after {
  transition: all 500ms ease;
  height: 100%;
}
.prog-button span:before,
.prog-button span:after {
  position: absolute;
  content: "";
  background: #000;
}
.prog-button span:before {
  left: 0;
  top: 0;
  width: 0%;
  height: 2px;
  transition: all 500ms ease;
}
.prog-button span:after {
  right: 0;
  bottom: 0;
  width: 0%;
  height: 2px;
  transition: all 500ms ease;
}
.prog-button span:hover:before {
  width: 100%;
}
.prog-button span:hover:after {
  width: 100%;
}


@media (max-width: 993px) {

  .stream-container {
    flex-direction: column;
  }

  .no-stream {
    width: 90%;
  height: 45%;

}

  #twitch-embed-live {
  width: 90%;
  height: 45%;

}

#twitch-embed-chat {
  width: 90%;
  height: 45%;
}
}

</style>
import { createApp } from 'vue'
import LoadScript from "vue-plugin-load-script";
import App from './App.vue'
import router from './router'
import store from './store'

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faTwitch,
    faXTwitter,
    faDiscord,
    faInstagram
  } from "@fortawesome/free-brands-svg-icons";

  library.add(faTwitch);
  library.add(faXTwitter);
  library.add(faDiscord);
  library.add(faInstagram);



createApp(App)
.use(store)
.use(router)
.use(LoadScript)
.component("font-awesome-icon", FontAwesomeIcon)
.mount('#app')
